<template>
  <TModal
    title="Assemble to stock"
    size="lg"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions> <span></span></template>
    <div class="mb-3" v-if="!lodash.isEmpty(data)">
      <div class="d-flex h4">
        <TMessage content="Box" />:
        <h4 class="ml-1">{{ box.id }}</h4>
      </div>
      <div class="d-flex">
        <TMessage content="Quantity in stock" bold />:
        <CBadge style="font-size: 90%" color="info" class="my-auto ml-1">
          {{ data.quantity_in_inventory }}
        </CBadge>
      </div>
      <div class="d-flex">
        <TMessage content="Available quantity" bold />:
        <CBadge style="font-size: 90%" color="info" class="my-auto ml-1">
          {{ data.quantity_available_in_pallet }}
        </CBadge>
      </div>
    </div>
    <div class="card border-info p-3" v-if="!lodash.isEmpty(pallet)">
      <div class="d-flex">
        <TMessage
          :content="pallet.pallet_id"
          size="h4"
          color="primary"
          noTranslate
        />
        <SMessagePalletLocation
          style="font-size: 90%"
          class="my-auto ml-1"
          v-if="pallet.pallet && pallet.pallet.location"
          :location="pallet.pallet.location"
        />
      </div>
      <div class="d-flex" v-if="!lodash.isEmpty(data)">
        <TMessage content="Quantity in pallets" bold />:
        <CBadge style="font-size: 90%" color="info" class="my-auto ml-1">
          {{ pallet.quantity }}
        </CBadge>
      </div>
      <TMessage content="Unload goods from pallets" bold class="h4 mt-3" />

      <div class="d-flex mb-3">
        <TInputNumber :value.sync="quantityRemoved" />
        <TButton
          :options="{
            disabled:
              quantityRemoved > pallet.quantity ||
              !quantityRemoved ||
              palletBoxLoading,
          }"
          class="ml-1"
          style="width: 100px"
          content="Remove"
          @click="remove"
        />
      </div>
      <div>
        <TMessage content="Convert pallet" bold class="h4" />
        <CRow>
          <CCol col="12" lg="6">
            <SSelectPallet
              label="Select pallet"
              :value.sync="pallet_id"
              class="mb-2"
            />
          </CCol>
          <CCol col="12" lg="6">
            <TInputNumber
              class="mb-2"
              :value.sync="quantitySwap"
              label="Quantity"
            />
          </CCol>
        </CRow>
        <TButton
          :options="{
            disabled:
              quantitySwap > pallet.quantity || !quantitySwap || loading,
          }"
          class="ml-auto"
          style="width: 100px"
          content="Change"
          @click="changePallet"
        />
      </div>
    </div>
    <div v-else>
      <TMessage content="Assemble to pallets" bold class="h4" />
      <CRow>
        <CCol col="12" lg="6">
          <SSelectPallet
            label="Select pallet"
            :value.sync="pallet_id"
            class="mb-2"
          />
        </CCol>
        <CCol col="12" lg="6">
          <TInputNumber
            class="mb-2"
            :value.sync="quantityPickPallet"
            label="Quantity"
          />
        </CCol>
      </CRow>
      <TButton
        :options="{
          disabled:
            quantityPickPallet > data.quantity_in_inventory ||
            !quantityPickPallet ||
            palletBoxLoading,
        }"
        class="ml-auto"
        style="width: 100px"
        content="Confirm"
        @click="enterSku"
      />
    </div>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
import barcode from "../mixins/barcode";
import audio from "../mixins/audio";
export default {
  mixins: [barcode, audio],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    box: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      editing: false,
      data: "",
      quantityRemoved: 0,
      quantitySwap: 0,
      quantityPickPallet: 0,
      pallet: {},
      pallet_id: "",
    };
  },
  watch: {
    box: {
      immediate: true,
      handler(box) {
        if (box.id) {
          this.pallet = {};
          this.quantityRemoved = 0;
          this.quantitySwap = 0;
          this.quantityPickPallet = 0;
          this.pallet_id = "";
          this.fetchSku();
        }
      },
    },
    data: {
      immediate: true,
      handler(data) {
        if (data.id) {
          this.pallet = data?.pivot_pallets.find(
            (x) => this.box.pallet_id == x.pallet_id
          );
        }
      },
    },
    show(isShow) {
      if (isShow) {
        const inputs = document.querySelectorAll("input");
        inputs[0].focus();
        inputs[0].blur();
      }
    },
  },
  computed: {
    ...mapGetters({
      palletBoxLoading: "warehouse.pallet_boxes.loading",
      palletList: "warehouse.pallets_list.list",
    }),
    quantityAvailable() {
      return this.box.total_quantity_in_lading_bills > this.pallet.quantity
        ? this.pallet.quantity
        : this.box.total_quantity_in_lading_bills;
    },
  },
  methods: {
    pickBarcode(e) {
      if (!this.show) return;
      const code = this.press(e);
      if (this.sfa_id_selected) return;
      if (code) {
        this.getPallet(code);
      }
    },
    getPallet(id) {
      const checkPalletExist = this.palletList.find((x) => x.id == id);
      if (checkPalletExist) {
        this.pallet_id = id;
        this.playSuccess();
      } else {
        this.$store
          .dispatch("warehouse.pallets_list.apply-query", {
            "filter[id]": id,
          })
          .then(({ data }) => {
            const pallet = data.find((x) => x.id == id);
            if (pallet) {
              this.pallet_id = id;
              this.playSuccess();
            } else this.playFail();
          });
      }
    },
    fetchSku() {
      this.$tomoni.warehouse.boxes
        .get(this.box.id, {
          include: "pivotPallets",
        })
        .then((data) => {
          this.data = data.data;
        });
    },
    remove() {
      if (this.pallet.quantity == this.quantityRemoved)
        this.removeSku(this.pallet);
      else this.updateQuantityInPallet(this.pallet, this.quantityRemoved);
    },
    removeSku(pivot_pallet) {
      this.$store
        .dispatch("warehouse.pallet_boxes.delete", pivot_pallet.id)
        .then(() => {
          this.$emit("updated");
          this.$emit("update:show", false);
        });
    },
    updateQuantityInPallet(pivot_pallet, quantity) {
      this.$store
        .dispatch("warehouse.pallet_boxes.update", {
          id: pivot_pallet.id,
          attributes: { quantity: pivot_pallet.quantity - quantity },
        })
        .then(() => {
          this.quantityRemoved = 0;
          this.fetchSku();
        });
    },
    changePallet() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.change_sku_position.change-position", {
          from_pallet_box_id: this.pallet.id,
          to_pallet_id: this.pallet_id,
          quantity: this.quantitySwap,
        })
        .then(() => {
          if (this.quantitySwap == this.pallet.quantity) {
            this.$emit("update:show", false);
          }
          this.pallet_id = "";
          this.quantitySwap = 0;
          this.fetchSku();
          this.$emit("updated");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enterSku() {
      this.$store
        .dispatch("warehouse.pallet_boxes.create", {
          "boxes[0][id]": this.box.id,
          pallet_id: this.pallet_id,
          "boxes[0][quantity]": this.quantityPickPallet,
        })
        .then(() => {
          this.$emit("updated");
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
